exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-statement-js": () => import("./../../../src/pages/accessibility-statement.js" /* webpackChunkName: "component---src-pages-accessibility-statement-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-current-management-js": () => import("./../../../src/pages/current-management.js" /* webpackChunkName: "component---src-pages-current-management-js" */),
  "component---src-pages-disease-burden-js": () => import("./../../../src/pages/disease-burden.js" /* webpackChunkName: "component---src-pages-disease-burden-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-member-impact-js": () => import("./../../../src/pages/member-impact.js" /* webpackChunkName: "component---src-pages-member-impact-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pathophysiology-js": () => import("./../../../src/pages/pathophysiology.js" /* webpackChunkName: "component---src-pages-pathophysiology-js" */),
  "component---src-pages-significance-of-exacerbations-js": () => import("./../../../src/pages/significance-of-exacerbations.js" /* webpackChunkName: "component---src-pages-significance-of-exacerbations-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

